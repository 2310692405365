/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

function ProductPreview ({ product }) {
  ProductPreview.propTypes = {
    product: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    }).isRequired,
  }

  return (
    <CardActionArea>
      <CardMedia
        component="img"
        height="140"
        width="140"
        src={product.image}
        alt={product.name}

        style={{ height: 150, width: 150, objectFit: 'contain' }}
      />
      <CardContent>
        <Typography variant="h5" component="div">
          {product.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
            {product.generic}
        </Typography>
      </CardContent>
    </CardActionArea>
  );
}

export default ProductPreview;
