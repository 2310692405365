/* eslint-disable */

import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";
import SignIn from "layouts/pages/authentication/sign-in";
import ProductList from "pages/LandingPages/Products";

import ManufactureForm from "pages/LandingPages/Manufacture";
import ProductForm from "pages/LandingPages/AddProduct";
import AllManufacturedTable from "pages/LandingPages/ViewAllBatches";
import Logout from "pages/LandingPages/Logout";

const mroutes = [
  {
    name: "All Products",
    route: "/products",
    component: <ProductList />,
    roles : [1,2]
  },
  {
    name: "Manager Operations",
    collapse: [
      {
        name: "Add New Product",
        route: "/pages/landing-pages/add-product",
        component: <ProductForm />,
        roles : [1,2]
      },
      {
        name: "Manufacture Products",
        route: "/pages/landing-pages/manufacture",
        component: <ManufactureForm />,
        roles : [1,2]
      },
      {
        name: "View all Manufactured Batches",
        route: "/pages/landing-pages/all-manufacture",
        component: <AllManufacturedTable />,
        roles : [1,2]
      },
    ],
  },
  {
    name: "About Us",
    route: "/pages/landing-pages/about-us",
    component: <Author />,
    roles : [1,2]
  },
  {
    name: "Distributors",
    route: "/pages/landing-pages/distributors",
    component: <AboutUs />,
    roles : [1,2]
  },
  {
    name: "Contact Us",
    route: "/contact-us",
    component: <ContactUs />,
    roles : [1,2]
  },
  {
    name: "Logout",
    route: "/logout",
    component: <Logout />,
    roles : undefined,
  },
];

export default mroutes;
