/* eslint-disable */
/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ApiCallGet } from "services/ApiCalls";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";
import Presentation from "layouts/pages/presentation";

// Material Kit 2 React routes
import routes from "routes";
import mroutes from "manager_routes";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
// import MKBox from "components/MKBox";
// import DefaultNavbar from "examples/Navbars/DefaultNavbar";

export default function App() {
  const { pathname } = useLocation();

  const [user, setUser] = useState()

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result1 = await ApiCallGet('/getUser');
        // console.log("Got the user",result1)
        // setUser(result1.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchData();
  }, []);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route
        //  && route.roles ? (route.roles.find(user.role)) : true    // Dont Uncomment FireWorks
         ) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <head>
        <link rel="icon" href="/favicon.png" type="image/x-icon"/>
      </head> */}
      <MKBox position="absolute" top="0.5rem" width="100%">
        <DefaultNavbar
          routes={user?mroutes:routes}
        />
      </MKBox>
      <Routes>
        {getRoutes(user?mroutes:routes)}
        <Route path="/" element={<Presentation />} />
        <Route path="*" element={<Navigate to="/presentation" />} />
      </Routes>
    </ThemeProvider>
  );
}
