/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Switch from "@mui/material/Switch";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Select from "components/Selector/Selector";
import { useForm } from "components/Form/HandleForm";
import { ApiCallPost } from "services/ApiCalls";
import { ApiCallGet } from "services/ApiCalls";
import { Card } from "@mui/material";
import SnackbarAlerts from "components/SnackbarAlert/SnackbarAlert";

function ManufactureForm() {
  const [products, setProducts] = useState([]);
  const [packSizes, setPackSizes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result1 = await ApiCallGet('/products');
      console.log('Products: ', result1);
      const productMenu = []
      if (result1?.data) {
        result1.data.map((prod) => {
          productMenu.push({ 'id': prod.id, 'title': prod.name })

        })
        setProducts(productMenu);
      }

      const result2 = await ApiCallGet('/pack-size');
      const productSizeMenu = []
      if (result2?.data) {
        setPackSizes(result2?.data);
      }

    }
    fetchData();
  }, []);
  const CurdateFormated = currentDate => currentDate.getFullYear() + '-' + (parseInt(currentDate.getMonth()) + 1) + '-' + currentDate.getDate();

  const initialValues = {
    product_id: '',
    pack_size_id: '',
    label_price: '',
    packing_price: '',
    quantity: '',
    batch_number: '',
    remarks: '',
    entered_on: CurdateFormated(new Date()),

  }
  const { values, setValues, handleChange } = useForm(initialValues);


  const [severity, setSeverity] = useState('');
  const [openSA, setOpenSA] = useState(false);
  const [msg, setMsg] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  }

  const submitData = async () => {

    console.log('Entering Submit Data ', values);
    const result = await ApiCallPost('/manufacture', { values: values });

    if (result.status !== 200) {
      console.log('ERROR:', result.text);
      setSeverity("error");
      setMsg(`Error! Batch ${result.text}`);
      setOpenSA(true);
    }
    else {
      setSeverity("success");
      setMsg("Manufacture Record Added!");
      setValues(initialValues)
      setOpenSA(true);
    }
  }


  return (
    <>
      <SnackbarAlerts open={openSA} setOpen={setOpenSA} severity={severity} msg={msg} />

      <MKBox component="section" py={12}>
        <Container>
          <Grid container item xs={12} lg={7} sx={{ mx: "auto" }}>
            <Card>
              <MKBox width="100%" component="form" onSubmit={handleSubmit}>
                {/* method="post"  */}
                <MKBox
                  variant="gradient"
                  bgColor="info"
                  coloredShadow="info"
                  borderRadius="lg"
                  p={2}
                  mx={2}
                  mt={-3}
                  sx={{ margin: '20px' }}
                >
                  <MKTypography variant="h3" color="white">
                    Manufacture Product Batch
                  </MKTypography>
                </MKBox>
                <MKBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                      <Select
                        label="Product"
                        name="product_id"
                        value={values.product_id}
                        onChange={handleChange}
                        options={products}
                        required

                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Select
                        label="Package Size"
                        name="pack_size_id"
                        value={values.pack_size_id ? values.pack_size_id : ''}
                        onChange={handleChange}
                        options={packSizes}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput id="packing_price" name="packing_price" variant="outlined" type="number" label="Packing Price" fullWidth value={values.packing_price}
                        onChange={handleChange}
                        inputProps={{ min: 1 }}
                        required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput id="label_price" name="label_price" variant="outlined" type="number" label="Label Price" fullWidth value={values.label_price}
                        inputProps={{ min: 1 }}
                        onChange={handleChange} required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput id="quantity" name="quantity" variant="outlined" type="number" label="Quantity" fullWidth value={values.quantity}
                        // onChange={(e) => setValues({ ...values, 'quantity': e.target.value })} 
                        inputProps={{ min: 1 }}
                        onChange={handleChange} required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MKInput id="batch_number" name="batch_number" variant="outlined" type="number" label="Batch Number" fullWidth value={values.batch_number}
                        inputProps={{ min: 1 }}
                        onChange={handleChange} required />
                    </Grid>

                    <Grid item xs={12}>
                      <MKInput id="remarks" name="remarks" variant="outlined" label="Remarks" multiline fullWidth rows={6}
                        onChange={handleChange} />
                    </Grid>

                    {/* onChange={(e) => setValues({ ...values, 'name': e.target.value })} */}
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} my={2}>
                    <MKButton
                      // onClick={submitManufacture}
                      type="submit"
                      variant="gradient" color="info" fullWidth>
                      Submit Product
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ManufactureForm;
