/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Switch from "@mui/material/Switch";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Select from "components/Selector/Selector";
import { useForm } from "components/Form/HandleForm";
import { ApiCallPost } from "services/ApiCalls";
import { ApiCallGet } from "services/ApiCalls";
import Select2 from "components/Selector/Selector2";
import SnackbarAlerts from "components/SnackbarAlert/SnackbarAlert";


function SaleInvoiceForm({data, toggleButton, setReload} = props) {
    const [products, setProducts] = useState([]);
    const [packSizes, setPackSizes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const result1 = await ApiCallGet('/products');
            console.log('Products: ', result1);
            const productMenu = []
            if (result1?.data) {
                result1.data.map((prod) => {
                    productMenu.push({ 'id': prod.id, 'title': prod.name })

                })
                setProducts(productMenu);
            }

            const result2 = await ApiCallGet('/pack-size');
            const productSizeMenu = []
            if (result2?.data) {
                setPackSizes(result2?.data);
            }

        }
        fetchData();
    }, []);
    const CurdateFormated = currentDate => currentDate.getFullYear() + '-' + (parseInt(currentDate.getMonth()) + 1) + '-' + currentDate.getDate();

    const initialValues = {
        batch_num: data.batch_num,
        discount: 0,
        sale_price: '',
        transportation_fee: '',
        dist_id: '1',
        entered_on: CurdateFormated(new Date()),
        currency_unit:'PKR'

    }
    const { values, setValues, handleChange } = useForm(initialValues);
    
  const [severity, setSeverity] = useState('');
  const [openSA, setOpenSA] = useState(false);
  const [msg, setMsg] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        submitData();
    }

    const submitData = async () => {

        console.log('Entering Submit Data ', values);
        const result = await ApiCallPost('/sale-invoice', { values: values });
        if (result.status !== 200) {
            console.log('ERROR:', result.text);
            setSeverity("error");
            setMsg("Invoice cannot be added at the moment!");
            setOpenSA(true);
          }
          else {
            setSeverity("success");
            setMsg("Sale Invoice Added!");
            setOpenSA(true);
            setValues(initialValues)
            setReload((old)=>{old+1})
            toggleButton()
          }
    }


    return (
        <>
            <SnackbarAlerts open={openSA} setOpen={setOpenSA} severity={severity} msg={msg} />
      
            <MKBox width="100%" component="form" onSubmit={handleSubmit}>
                <MKBox p={3}>
                    {console.log(data)}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <MKInput
                                label="Batch Number"
                                name="batch_num"
                                value={values.batch_num}
                                required
                                variant="standard" 
                                disabled
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={8}>
                            <Select2
                                label="Distributor"
                                name="dist_id"
                                value={values.dist_id}
                                onChange={handleChange}
                                options={products}
                                required
                                variant="standard" 

                            />
                        </Grid> */}

                        <Grid item xs={12} md={8}>
                            <MKInput id="sale_price" name="sale_price" variant="standard" type="number" label="Sale Price" fullWidth value={values.sale_price}
                                onChange={handleChange}
                                inputProps={{ min: 1 }}
                                required />
                        </Grid>
                        
                        <Grid item xs={12} md={4}>
                            <MKInput id="currency_unit" name="currency_unit" disabled variant="standard" type="text" label="Currency" fullWidth value={values.currency_unit} required />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MKInput id="transportation_fee" name="transportation_fee" variant="standard" type="number" label="Transportation Fee" fullWidth value={values.transportation_fee}
                                inputProps={{ min: 1 }}
                                onChange={handleChange} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MKInput id="discount" name="discount" variant="standard" type="number" label="Discount" fullWidth value={values.discount}
                                inputProps={{ min: 0 }}
                                onChange={handleChange} required />
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} my={2}>
                        <MKButton
                            type="submit"
                            variant="gradient" color="info" fullWidth>
                            Submit Sale Invoice
                        </MKButton>
                    </Grid>
                </MKBox>
            </MKBox>
        </>
    );
}

export default SaleInvoiceForm;
