/* eslint-disable */

import * as React from 'react';

import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import { Grid } from '@mui/material';
import MKButton from 'components/MKButton';


const ViewInvoice = ({ data, toggleButton } = props) => {


    return (
        <>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Batch Number</strong></MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.batch_num}</MKTypography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Label Price</strong></MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.label_price}</MKTypography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Packing Price</strong></MKTypography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.packing_price}</MKTypography>
                    <hr />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Total Manufacturing Price</strong></MKTypography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.packing_price + data.label_price}</MKTypography>
                    <hr />
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Sale Price</strong></MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.sale_price}</MKTypography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Transportation Fee</strong></MKTypography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.transportation_fee}</MKTypography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Discount</strong></MKTypography>
                    <hr/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.discount}</MKTypography>
                    <hr/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Total Earning from Sales</strong></MKTypography>
                    <hr/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.sale_price-data.transportation_fee-data.discount}</MKTypography>
                    <hr/>
                </Grid>

                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular"><strong>Profit/ Loss</strong></MKTypography>

                </Grid>
                <Grid item xs={12} md={6}>
                    <MKTypography variant="body2" color="secondary" fontWeight="regular">{data.sale_price-data.transportation_fee-data.discount-data.packing_price -data.label_price}</MKTypography>
                    
                </Grid>
                <Grid item xs={12} md={8}></Grid>
                <Grid item xs={12} md={4}><MKButton variant="gradient" color="primary" fullWidth onClick={toggleButton}>Close</MKButton></Grid>
            </Grid>
            
        </>
    );
}

export default ViewInvoice;