/* eslint-disable */
import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Switch from "@mui/material/Switch";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import routes from "routes";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Select from "components/Selector/Selector";
import { useForm } from "components/Form/HandleForm";
import { FormControl, FormHelperText, InputLabel, MenuItem } from "@mui/material";

import bgImage from "assets/images/bg-presentation-2.jpg";
import { ApiCallPost } from "services/ApiCalls";
import SnackbarAlerts from "components/SnackbarAlert/SnackbarAlert";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

function ProductForm() {

  const CurdateFormated = currentDate => currentDate.getFullYear() + '-' + (parseInt(currentDate.getMonth()) + 1) + '-' + currentDate.getDate();

  const initialValues = {
    name: '',
    generic: '',
    description: '',
    image: null,
    entered_on: CurdateFormated(new Date()),

  }
  const { values, setValues, handleChange } = useForm(initialValues);
  //   const function submitManufacture

  const handleSubmit = (e) => {
    e.preventDefault();
    submitData();
  }

  const [severity, setSeverity] = useState('');
  const [openSA, setOpenSA] = useState(false);
  const [msg, setMsg] = useState('');


  const submitData = async () => {

    console.log('Entering Submit Data ', values);
    const result = await ApiCallPost('/product', { values: values });
    console.log(result)
    if (result.status !== 200) {
      console.log('ERROR:', result.text);
      setSeverity("error");
      setMsg(`Error! Product ${result.text}`);
      setOpenSA(true);
    }
    else {
      setSeverity("success");
      setMsg("Product Added!");
      setOpenSA(true);
      setValues(initialValues)
    }
  }


  return (
    <>
      {/* <SnackbarAlerts msg={msg} severity={severity} open={openSA} />
       */}
      <SnackbarAlerts open={openSA} setOpen={setOpenSA} severity={severity} msg={msg} />
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${bgImage})` }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={7}
          lg={6}
          xl={4}
          ml={{ xs: "auto", lg: 6 }}
          mr={{ xs: "auto", lg: 6 }}
        >
          <MKBox
            bgColor="white"
            borderRadius="xl"
            shadow="lg"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            mt={{ xs: 20, sm: 18, md: 20 }}
            mb={{ xs: 20, sm: 18, md: 20 }}
            mx={3}
          >
            <MKBox
              variant="gradient"
              bgColor="info"
              coloredShadow="info"
              borderRadius="lg"
              p={2}
              mx={2}
              mt={-3}
            >
              <MKTypography variant="h3" color="white">
                Add Product
              </MKTypography>
            </MKBox>
            <MKBox p={3}>
              <MKBox width="100%" component="form" method="post" autocomplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <MKInput id="name" name="name" variant="outlined"  type="text" label="Product Name" fullWidth value={values.name}
                    onChange={handleChange}
                    required/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <MKInput id="generic" name="generic" variant="outlined" type="text" label="Generic" fullWidth value={values.generic}
                    onChange={handleChange} required/>
                    </Grid>
                    <Grid item xs={12} md={12}>
                    <MKInput id="image" name="image" variant="outlined" type="text" label="Image URL" fullWidth value={values.image}
                    onChange={handleChange}/>
                    </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      id="description" 
                      name="description"
                      variant="outlined"
                      value={values.description}
                      onChange={handleChange}
                      label="Product Uses and Description"
                      placeholder="Describe product uses and description along with cautions..."
                      InputLabelProps={{ shrink: true }}
                      multiline
                      fullWidth
                      rows={6}
                    />
                  </Grid>
                </Grid>
                <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                  <MKButton type="submit" variant="gradient" color="info" 
                  // onClick={handleSubmit}
                  >
                    Submit Product
                  </MKButton>
                </Grid>
              </MKBox>
            </MKBox>
          </MKBox>
        </Grid>
      </Grid>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
        {/* </Container> */}
      {/* </MKBox> */}
    </>
  );
}

export default ProductForm;
