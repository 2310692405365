/* eslint-disable */
import React from "react";
// import { Grid, Box } from "@mui/material";

// import menuItem from "assets/theme/components/menu/menuItem";

import MKBox from "components/MKBox";
import Item from "components/Grid/Item";
// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";
// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import { Container, Grid, Typography } from "@mui/material";
import { ApiCallGet } from "services/ApiCalls";
import ProductPreview from "./Preview";
import MKTypography from "components/MKTypography";

function ProductList() {
  
  const [products, setProducts] = React.useState([]);
  React.useEffect(() => {
    console.log('I am going to fetch data')
      const fetchData = async () => {
        const result1 = await ApiCallGet('/products');
        console.log('Products: ', result1);
        if(result1?.data){
          setProducts(result1.data);
        }
        
      }
      fetchData();
    }, []);

  return (
    <>
      {/* <MKBox position="absolute" top="0.5rem" width="100%">
        <DefaultNavbar routes={routes} />
      </MKBox> */}
      <div style={{padding:"60px"}}/>
      <Container>

      
      <Grid container spacing={3} alignItems="center">
        {/* <Grid item sx={12} justifyContent="center" textAlign="center">
            <Typography variant="h5" component="div" color="text.secondary">
              All Products
            </Typography>
        </Grid> */}
        <Grid container item justifyContent="center" xs={10} lg={7} mx="auto" textAlign="center">
            <MKTypography variant="h3" mb={1}>
                All Products
            </MKTypography>
            </Grid>
        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid key={product.retailer_sku} item xs={2}>
              <Item>
                <ProductPreview product={product} />
                {console.log(products)}
              </Item>
            </Grid>
          ))}
        </Grid>
      </Grid>
      </Container>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ProductList;
