/* eslint-disable */

import axios from 'axios';

const baseUrl = "http://localhost:4000/api";
const headers = { 'Content-Type': 'application/json'};
const options = { headers: headers, withCredentials: true };

const getError = (error) => {

  if (error.response) {
    if (error.response.status === 500) {
      return { text: "Internal Server Error", error: error };
    } else if (error.response.status === 422) {
      return { text: "Cannot Process Please Try Again", error: error };
    } else if (error.response.status === 405) {
      return { text: "Not Found", error: error };
    } else if (error.response.status === 406) {
      return { text: "Already Exist", error: error };
    } else if (error.response.status === 404) {
      return { text: "API Not Found", error: error };
    } else if (error.response.status === 444) {
      return { text: "Invalid Data", error: error };
    } else if (error.response.status === 430) {
      return { text: error.response.data, error: error };
    }
    else {
      return { text: "Unkown Error", error: error };
    }
  } else {
    return { text: "No Internet Connection", error: error };
  }

}

const getResponse = (response, redirect) => {

  if (response.status === 202 && redirect === true) {
    window.location.replace(response.data);
  } else {
    return response;
  }

}

const ApiCallGet = (path, redirect = true) => {

  return axios.get(baseUrl + path, options)
    .then((response) => {
      return getResponse(response, redirect);
    })
    .catch((error) => {
      return getError(error);
    });

}

const ApiCallPost = (path, data, redirect = true) => {

  return axios.post(baseUrl + path, data, options)
    .then((response) => {
      return getResponse(response, redirect);
    })
    .catch((error) => {
      return getError(error);
    });

}

export { ApiCallGet, ApiCallPost };


// async function APIGet(url) {
//   try {
//     const fullurl = `${baseURL}${url}`;
//     const response = await fetch(fullurl);
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const data = await response.json();
//     return data;
//   } catch (error) {
//     console.error("API GET Error:", error);
//     return null;
//   }
// }

// async function APIPost(url, data) {
//   try {
//     const fullurl = `${baseURL}${url}`;
//     console.log("The full URL is ", fullurl)
//     const response = await fetch(fullurl, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(data),
//     });
//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }
//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {
//     console.error("API POST Error:", error);
//     return null;
//   }
// }

// import axios from 'axios';


// const handleResponse = (response) => response.data;

// const handleError = (error) => {
//   console.error('API Error:', error);
//   throw error; 
// };

// export {APIGet, APIPost}

// export const APIGet = (endpoint) => {
//   const url = `${baseURL}${endpoint}`;
//   return axios.get(url).then(handleResponse).catch(handleError);
// };

// export const APIPost = (endpoint, data) => {
//   const url = `${baseURL}${endpoint}`;
//   return axios.post(url, data).then(handleResponse).catch(handleError);
// };
