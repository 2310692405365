/* eslint-disable */

import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';

import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import SaleInvoiceForm from '../AddSaleInvoice';
import ViewInvoice from './ViewInvoice/ViewInvoice';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export default function ManTable({ data, setReload } = props) {


    const [show, setShow] = React.useState(false);
    const [modalData, setModalData] = React.useState({});
    const toggleModal = () => {
        setShow(!show);
        
        
    }

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const handleClick = (event, row) => {
        console.log("I am clicked", row)
        setModalData(row)
        toggleModal()

    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


    const visibleRows = React.useMemo(
        () =>
            data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            )
    );
    return (
        <Box sx={{ width: '100%' }}>
            {console.log('Man Table is created with the data', data)}
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        size='medium'
                    >
                        <TableBody >
                            <TableRow >
                                <TableCell>
                                    <b>Batch#</b>
                                </TableCell>
                                <TableCell>
                                    <b>Product</b>
                                </TableCell>
                                <TableCell>
                                    <b>Product Size</b>
                                </TableCell>
                                <TableCell>
                                    <b>Quantity</b>
                                </TableCell>
                                <TableCell>
                                    <b>Manufacturing Price</b>
                                </TableCell>
                                <TableCell>
                                    <b>Entered On</b>
                                </TableCell>
                                <TableCell>
                                    <b>Operations</b>
                                </TableCell>
                            </TableRow>

                            {visibleRows.map((row, index) => {
                                return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                        sx={{ cursor: 'pointer' }}
                                        onClick={(event) => handleClick(event, row)}
                                    >
                                        <TableCell component="th" scope="row" padding="none" align="left">{row.batch_num}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" align="left">{row.product_name}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" align="left">{row.prod_size}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" align="left">{row.quantity}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" align="left">{row.label_price + row.packing_price} PKR</TableCell>
                                        <TableCell component="th" scope="row" padding="none" align="left">{row.entered_on.split('T')[0]}</TableCell>
                                        <TableCell component="th" scope="row" padding="none" align="center">
                                            {(row.sale_price) ? <MKButton variant="gradient" color="primary">View Invoice</MKButton> : <MKButton variant="gradient" color="info">Add Invoce</MKButton>

                                            }

                                        </TableCell>
                                        {/* 
                                        , si.transportation_fee, si.sale_price, si.discount */}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {show && (<Modal open={show} onClose={toggleModal} sx={{ display: "grid", placeItems: "center" }}>
                <Slide direction="down" in={show} timeout={500}>
                    <MKBox
                        position="relative"
                        width="500px"
                        display="flex"
                        flexDirection="column"
                        borderRadius="xl"
                        bgColor="white"
                        shadow="xl"
                    >
                        <MKBox display="flex" alignItems="center" justifyContent="space-between" p={2}>
                            <MKTypography variant="h5">Add Sale Invoice</MKTypography>
                            <CloseIcon fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                        </MKBox>
                        <Divider sx={{ my: 0 }} />
                        <MKBox p={2}>
                            {modalData?.sale_price?
                            <ViewInvoice data={modalData} toggleButton={toggleModal}/>:<SaleInvoiceForm data={modalData} setReload={setReload} toggleButton={toggleModal} />}
                            
                        </MKBox>
                        <Divider sx={{ my: 0 }} />
                        
                    </MKBox>
                </Slide>
            </Modal>)}

        </Box>

    );
}