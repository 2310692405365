/* eslint-disable prettier/prettier */

/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
// import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/bruce-mars.jpg";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Dr. Fariha Naz Atif</MKTypography>
                {/* <MKButton  variant="outlined" color="info" size="small" href="/">
                  Connect on LinkedIn
                </MKButton> */}
              </MKBox>
              <MKTypography variant="h5"> CEO</MKTypography>

              <MKTypography variant="body1" fontWeight="light" color="text" >
                Dr. Fariha Naz Atif is a highly qualified professional with a Doctor of Pharmacy degree from Punjab University College of Pharmacy. With extensive experience in the pharmaceutical industry, she has served as an operational manager and export manager. Dr. Atif has demonstrated her global expertise by establishinig linkage with distributors in countries
                such as Sri Lanka, Kenya, Azerbaijan, Uzbekistan, and the UAE.

                <br />
                In addition to her managerial roles, Dr. Atif is actively involved in providing
                consultancy services for the establishment of pharmaceutical units. Her commitment to excellence is evident through her dedicated team. With a strong background in pharmacy and a wealth of industry experience, Dr. Fariha Naz Atif is a valuable asset in the pharmaceutical field, ensuring effective distribution and contributing to the successful setup of pharmaceutical units.

              </MKTypography>

              <br />
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">Waqas Ali Khan</MKTypography>
                {/* <MKButton variant="outlined" color="info" size="small" href="https://www.linkedin.com/in/widad-khan-9b97a01b3/">
                  Connect on LinkedIn
                </MKButton> */}
              </MKBox>
              <MKTypography variant="h5"> Director (Regulatory Affairs) </MKTypography>
              <MKTypography variant="h6"> Key Expertise: Pharmaceuticals (Regulatory Affairs & Quality Operations) </MKTypography>
              <br />

              <MKTypography variant="body1" fontWeight="light" color="text" >
                 Accomplished regulatory professional with more than 14+ years of in-
                depth experience in the Development, Registration and Licensing

                of innovative and follow-on medicinal products.
                Freelance Regulatory Consultant to the Pharmaceutical (Drugs, Medical
                Devices, Cosmetics, Food & Supplement) Industry as of 2020.
                Leading Regulatory Affairs / Quality Operations Professional from
                Medical Transcriptionist to Head of RA, having Drug Development
                experience, Registration of Products within global regulatory affairs
                platform across the various therapeutic areas.
                Proven scientific, regulatory and managerial expertise in driving both
                early development teams and full development.
                Prove ability to lead and manage quality teams through complex global
                regulatory submissions and interactions with numerous global health
                authorities.
                <br />
                Engage with Regulatory Affairs & International Business Development
                activities in the following countries; Afghanistan, Cameroon, Conakry,
                Kenya, Papaguena, Liberia, Mali, Maldives, Ivory Coast, Sierra Leone,
                Tanzania, Sudan, Uganda, Ghana, Congo, Laos, GCC Countries, Somalia,
                Burundi, Mauritius, Peru, Sri lanka, Philippines, Vietnam, , Myanmar,
                Cambodia, Iraq, Kuwait, Yemen, Tajikistan, Turkmenistan, Kyrgyzstan,
                Zambia, Nigeria, Kosovo, Yemen, Azerbaijan.
                Achieved Safety, Environmental & Manufacturing licence approval for
                New Established Company in Asian & GCC Regions.
                Implementation of Regulatory Affairs Management System in respect of
                strategic & operational activities. Standardized Effective Pre & Post
                Market Regulatory Activities.
                <br />
                Launched the inspection programs in the industry with the systematic
                collection and use of data throughout the inspection process
                Improved product approval decisions by incorporating targets with
                expertise.
                <br />
                Supporting the safe use of new drugs in children through industry
                incentives and critical clinical data.
              </MKTypography>


              {/* <MKTypography
               
                  component="a"
                  href="#"
                  variant="body1"
                  fontWeight="light"
                  color="info"
                  mt={3}
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      transform: `translateX(3px)`,
                      transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round": {
                      transform: `translateX(6px)`,
                    },
                  }}
                  
                >
                  More about me <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </MKTypography> */}

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
