/* eslint-disable */
import React, { useEffect } from 'react';
import { ApiCallGet } from 'services/ApiCalls';


const Logout = () => {


    useEffect(() => {
        const logout = async () => {
            const result = await ApiCallGet('/logout');
            console.log(result, "-------------------------------")
            // if (result.error) {
            //     window.location.href = '/';
            //     // history.push('/error');
            // } else {
            //     window.location.href = '/';
            //     // history.push('/');
            // }
        }
        logout();
    }, []);

    return (

        <>
        </>

    );

}

export default Logout;



// import Loading from 'components/Loading/Loading';
// import React, { useEffect } from 'react';
// import { useHistory } from 'react-router';
// import { ApiCallGet } from 'services/ApiCalls';

// const Logout = () => {

//     const history = useHistory();

//     useEffect(() => {
//         const logout = async () => {
//             const result = await ApiCallGet('/logout');
//             if (result.error) {
//                 history.push('/error');
//             } else if(result.status === 201) { history.push('/login-oric')
//                } else{
//             history.push('/');
//        } }
//         logout();
//     }, [history]);

//     return (

//         <>

//             <Loading />

//         </>

//     );

// }

// export default Logout;
