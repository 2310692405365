/* eslint-disable*/

import React from 'react'
import { FormControl, InputLabel, Select as MuiSelect, MenuItem, FormHelperText, TextField } from '@mui/material';

export default function Select(props) {

    const { name, label, value, error = null, onChange, options } = props;

    return (
        <FormControl fullWidth variant="standard"
        style={{height:"40px", border:"1px solid #D3D3D3", borderRadius:"9px", margin:"5px"}}
        // sx={{ m: 1, minHeight: 30 }}
            {...(error && { error: true })}>
            <InputLabel>{`${label}${props.required ? ` *` : ``}`}</InputLabel>
            <MuiSelect
                
                value={value ?? " "}
                label={`${label}${props.required ? `*` : ``}`}
                name={name}
                onChange={onChange}
                disabled={props.disabled}
                required={props.required}
                fullWidth
                {...(error && { error: true, helperText: error })}
                renderInput={(params) => (
                    <TextField {...params} {...props} fullWidth label={label} variant="standard" />
                )}
                // defaultValue=''

                // renderInput={(params) => (
                //     <MKInput id="label_price" variant="outlined" label="Choose Product" fullWidth />
                // )}

                // <MKInput id="label_price" variant="outlined" label="Choose Product" fullWidth />


                // labelId="demo-simple-select-helper-label"
                // id="demo-simple-select-helper"
                // value={age}
                // label="Age"
                // onChange={handleChange}
            >
                {props.required ? null : <MenuItem value="">None</MenuItem>}

                {props.children ? props.children : null}
                {
                    options.map(
                        item => (<MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>)
                    )
                }
            </MuiSelect>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}
