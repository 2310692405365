/* eslint-disable */
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import routes from "routes";
import { useForm } from "components/Form/HandleForm";
import { ApiCallPost } from "services/ApiCalls";
import { ApiCallGet } from "services/ApiCalls";
import MKBadge from "components/MKBadge";
import { Card, Table } from "@mui/material";
import MKBox from "components/MKBox";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import ManTable from "./ManTable";
import DefaultFooter from "examples/Footers/DefaultFooter";

import footerRoutes from "footer.routes";

function AllManufacturedTable() {
    const [manRecords, setManRecords] = useState([]);
    const [saleInvoices, setSaleInvoices] = useState([]);
    const [reload, setReload] = useState(1)

    useEffect(() => {
        const fetchData = async () => {
            const result1 = await ApiCallGet('/manufacture');
            if (result1?.data) {
                setManRecords(result1.data);
            }

            const result2 = await ApiCallGet('/sale-invoice');
            if (result2?.data) {
                setSaleInvoices(result2.data);
                console.log("Sale Invoice",result2.data)
            }
        }
        fetchData();
    }, [reload]);
    return (
        <>
            <MKBox component="section" py={12}>
                <Container>
                    <Grid container item xs={12} lg={10} sx={{ mx: "auto" }}>
                        <MKBox width="100%" >
                            <MKBox
                                variant="gradient"
                                bgColor="info"
                                coloredShadow="info"
                                borderRadius="lg"
                                p={2}
                                mx={2}
                                mt={-3}
                                sx={{ margin: '20px' }}
                            >
                                <MKTypography variant="h3" color="white">
                                    All Manufactured Batches
                                </MKTypography>
                            </MKBox>
                            <MKBox p={3}>
                                <Grid container spacing={3}>
                                    {(manRecords.length) ? <ManTable data={manRecords} setReload={setReload} /> : <></>}

                                    {/* <ManTable data={manRecords}/> */}

                                </Grid>

                            </MKBox>
                        </MKBox>
                    </Grid>
                </Container>

            </MKBox>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default AllManufacturedTable;
